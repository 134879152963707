//
// page-head.scss
//

.page-title-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .page-title {
        font-size: 18px;
        margin: 0;
        line-height: 75px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $page-title-color;
    }

    .page-title-right {
        display: flex;
        flex-direction: row;
        gap: .25rem;
    }

    .breadcrumb {
        padding-top: 8px;
    }
}

.text-title {
    color: $text-title-color;

    &:hover {
        color: $text-title-color;
    }
}

@include media-breakpoint-down(md) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }

        .breadcrumb {
            display: none;
        }
    }
}
