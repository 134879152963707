@import "variables";

@import "theme/scss/app-creative";
@import "theme/scss/icons";

@import '~@simonwep/pickr/dist/themes/monolith.min.css';

@import "dropzone";
@import "filters";

label.required:after {
    content: " *";
    color: red;
}

.table-listing {
    margin-bottom: 0;

    thead > th {
        background-color: rgb(249, 250, 251);
        color: $gray-700;
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }

    .index {
        padding-right: 0;
        width: 35px;
        text-align: center;
    }

    .sort-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .sort-icon {
            width: 1rem;
            height: 1rem;
        }
    }

    tbody td {
        vertical-align: middle;
    }
}

.listing-summary {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pagination {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.opacity-50 {
    opacity: .5;
}

.opacity-70 {
    opacity: .7;
}

.cell-no-wrap {
    width: 1px;
    white-space: nowrap;
}

.pickr .pcr-button {
    border: 1px solid #222;
}
