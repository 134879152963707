
.listing-filters {
    padding: 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .5rem;
    }

    .btn-add-filter {
        display: flex;
        align-items: center;
        position: relative;

        svg {
            width: 1rem;
            height: 1rem;
            margin-right: .5rem;
        }
    }
}
